import { cn } from '@/lib/utils';
import { NextSeo } from 'next-seo';
import { GetServerSideProps } from 'next';
import { AnonymousBanner } from '@/components/AnonymousBanner';
import { SidebarLayout } from '@/components/layout/SidebarLayout';
import { Text } from '@/components/Text';
import { CharactersList } from '@/components/page-components/home/CharactersList';
import { CharectersListSkeleton } from '@/components/page-components/home/CharectersListSkeleton';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { useCharactersStore } from '@/stores/characters';
import { CustomBannerCarousel } from '@/components/page-components/home/CustomBannerCarousel';
import { Footer } from '@/components/Footer';
import { StaggeredContentLayout } from '@/components/page-components/layout/StaggeredContentLayout';
import { IconCardGrid } from '@/components/page-components/layout/IconCardGrid';
import { AccordionList } from '@/components/page-components/layout/AccordionList';
import { RefreshCw, MessageCirclePlus, Image, Lock } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Filter } from 'lucide-react';
import { CharacterFilterTags, CharacterSortType } from '@/models/characters';
import { FilterModal } from '@/components/FilterModal';
import { Modals } from '@/types/modals';
import { openModal } from '@/components/BaseModal';
import Head from 'next/head';

const checkFilterTags = (tags: string[], filterTagsSelected: string[]) => {
  if (filterTagsSelected.length == 0) {
    return true;
  }

  // Group tags by category (split by ':')
  const tagsByCategory = filterTagsSelected.reduce((acc: {[key: string]: string[]}, tag) => {
    const [category] = tag.split(':');
    if (!acc[category]) acc[category] = [];
    acc[category].push(tag);
    return acc;
  }, {});

  // For each category, at least one tag must match (OR within category)
  // All categories must have a match (AND between categories) 
  return Object.entries(tagsByCategory).every(([category, categoryTags]) => {
    return categoryTags.some(filterTag => tags.indexOf(filterTag) !== -1);
  });
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const protocol: string = process.env.NODE_ENV === 'production' ? 'https' : 'http';
  const baseUrl: string = `${protocol}://${req.headers.host}`;

  // Fetch characters server-side for SEO
  let preloadedCharacters = [];
  try {
    const charactersApi = await import('@/api/characters').then(mod => mod.default);
    preloadedCharacters = await charactersApi.getActiveCharsStatic();
  } catch (error) {
    console.error('Error fetching characters for SSR:', error);
    // Continue even if character fetching fails - we'll load them client-side as fallback
  }

  return {
    props: {
      canonicalUrl: baseUrl,
      pageType: 'female',
      preloadedCharacters: preloadedCharacters || [],
    },
  };
};

export default function Home({ canonicalUrl, pageType, preloadedCharacters }: { canonicalUrl: string, pageType: string, preloadedCharacters: any[] }) {
  const { characters, loading, filterTagsSelected, setFilterTagsSelected, sortTagSelected } = useCharactersStore(s => ({
    characters: s.characters,
    loading: s.loading,
    filterTagsSelected: s.filterTagsSelected,
    setFilterTagsSelected: s.setFilterTagsSelected,
    sortTagSelected: s.sortTagSelected,
  }));

  const sortSystemCharacters = () => {
    // Use preloaded characters if client-side store is empty
    const sourceCharacters = characters.length > 0 ? characters : preloadedCharacters;
    
    return sourceCharacters.filter(c => c.created_by_user === false && checkFilterTags(c.tags || [], filterTagsSelected))
      .sort((a, b) => {
        if (sortTagSelected === CharacterSortType.POPULAR) {
          // If either sort_rank is missing, put it last
          if (!a.sort_rank) return 1;
          if (!b.sort_rank) return -1;
          return a.sort_rank - b.sort_rank; // Lowest rank first
        } else {
          if (!a.created_at) return 1;
          if (!b.created_at) return -1;
          return (new Date(b.created_at).getTime()) - (new Date(a.created_at).getTime());
        }
      });
  }

  const [systemCharacters, setSystemCharacters] = useState(sortSystemCharacters());
  const isLoggedIn = useIsAuthenticated();
  const router = useRouter();
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  // Set initial filter based on page type
  useEffect(() => {
    if (pageType === 'female') {
      // Force update the filter to female characters
      setFilterTagsSelected(['gender:female']);
    }
  }, [pageType, setFilterTagsSelected]);

  useEffect(() => {
    setSystemCharacters(sortSystemCharacters());
  }, [filterTagsSelected, sortTagSelected, characters]);

  const staggeredSections = [
    {
      title: "The Ultimate NSFW AI Girlfriend Experience",
      subtitle: "Create your perfect virtual girlfriend companion for free",
      description: "Our AI characters will send you pictures, engage in roleplay, and explore your fantasies. No censorship, no judgment, just pure uncensored fun with AI companions who are always ready to chat.\n\nWhether you're looking for a casual conversation, want to explore specific scenarios, or just want to chat with someone who understands your desires, our AI girlfriend chatbot is here for you. Plus, with our advanced image generation, you can actually see your AI girlfriend come to life with pictures she sends you!",
      buttonText: "Create your AI Character Now",
      buttonAction: () => router.push('v3/character/create'),
      imageSrc: "https://images.thotchat.ai/seo/ai-chat-preview.png",
      imageAlt: "AI Girlfriend Chat Preview"
    }
  ];

  const featuresContent = {
    sectionTitle: "Why ThotChat is the Best AI Girlfriend Experience",
    sectionSubtitle: "Chat with AI characters Now",
    cards: [
      {
        icon: <Lock className="w-6 h-6 text-white" />,
        title: "100% Private & Secure",
        description: "Your chats and generated content stay completely private. What happens with your AI girlfriend, stays between you two."
      },
      {
        icon: <Image className="w-6 h-6 text-white" />,
        title: "AI Girlfriends Send Pictures",
        description: "Our AI-powered image generator creates unique, personalized pictures of your AI girlfriend. Text and she'll send you pictures."
      },
      {
        icon: <RefreshCw className="w-6 h-6 text-white" />,
        title: "Unblocked AI Character Chat",
        description: "Explore AI character chat without restrictions. Our platform offers character AI unblocked for the most natural conversations."
      },
      {
        icon: <MessageCirclePlus className="w-6 h-6 text-white" />,
        title: "Smart & Responsive Chatbot",
        description: "Our AI girlfriend chatbot remembers your preferences and adapts to your style, creating truly personalized experiences."
      }
    ]
  };

  const faqContent = {
    sectionTitle: "Frequently Asked Questions",
    sectionSubtitle: "Everything you want to know about AI girlfriends and characters",
    items: [
      {
        id: 'ai-girlfriend',
        question: "What is an AI girlfriend?",
        answer: "An AI girlfriend is a virtual companion powered by artificial intelligence that you can chat with, roleplay scenarios, and even receive pictures from. Our AI girlfriend chat simulator online lets you create your personalized AI girlfriend for free, with customizable personalities and appearances."
      },
      {
        id: 'image-gen',
        question: "Can my AI girlfriend send me pictures?",
        answer: "Yes! Our advanced AI nude generator can create unique pictures of your AI girlfriend based on your descriptions and preferences. When you text, she'll send you pictures that match your conversation. Our AI-powered nude image generator creates personalized content just for you."
      },
      {
        id: 'character-types',
        question: "What types of AI characters can I chat with?",
        answer: "We offer a wide variety of AI characters, including traditional girlfriends, AI Futa characters for roleplay chat, and many other personality types. All our character AI is unblocked, allowing for natural and unrestricted conversations."
      },
      {
        id: 'customization',
        question: "Can I create my own AI girlfriend?",
        answer: "Absolutely! You can create and customize your own AI girlfriend with specific personalities, interests, and preferences. You can also extensively customize her appearance. Create your perfect virtual girlfriend companion for free today!"
      }
    ]
  };

  const faqSchema: Record<string, unknown> = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqContent.items.map((item: { id: string; question: string; answer: string }): Record<string, unknown> => ({
      "@type": "Question",
      "name": item.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answer
      }
    }))
  };

  // Generate structured data for characters
  const charactersSchema = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": systemCharacters.map((character, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "item": {
        "@type": "Thing",
        "name": character.name,
        "description": character.description || "",
        "url": `${canonicalUrl}/character/${character.id}`,
        "image": character.avatar_url || (character.avatar_urls && character.avatar_urls.length > 0 ? character.avatar_urls[0] : "")
      }
    }))
  };

  const handleFilterButtonClick = () => {
    openModal(Modals.Filter);
  };

  return (
    <>
      <NextSeo
        title="ThotChat.AI - NSFW AI Characters with No Filters - AI GF"
        description="Experience unrestricted NSFW AI characters who send pics and chat without filters. Create your own AI Girlfriend"
        canonical={canonicalUrl}
        noindex={false}
        nofollow={false}
        openGraph={{
          url: canonicalUrl,
          title: 'ThotChat.AI - No Filter NSFW AI Characters',
          description: 'Chat with AI characters who send you pictures. Explore unfiltered AI characters and enjoy uncensored conversations.',
          site_name: 'ThotChat',
          images: [
            {
              url: 'https://images.thotchat.ai/seo/ai-chat-preview.png',
              width: 1200,
              height: 630,
              alt: 'ThotChat AI Girlfriend Characters Preview',
            }
          ],
          type: 'website',
        }}
        twitter={{
          handle: '@thotchat_ai',
          site: '@thotchat_ai',
          cardType: 'summary_large_image',
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: 'AI girlfriend, free AI girlfriend with pictures, AI girlfriend chatbot, chat with AI characters for free, AI girlfriend chat simulator online, AI nude generator, character AI unblocked, AI Futa characters'
          },
          {
            name: 'author',
            content: 'ThotChat'
          }
        ]}
      />
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(faqSchema) }} key="faq-jsonld" />
        {systemCharacters && systemCharacters.length > 0 && (
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(charactersSchema) }} key="characters-jsonld" />
        )}
      </Head>
      <SidebarLayout>
        <div className="flex flex-col min-h-[calc(100dvh-60px)] justify-between">
          <div className={''}>
            <CustomBannerCarousel bannerId="ai-girlfriend" />
          </div>
          <div className="relative flex flex-col">
            <div className="flex items-center justify-center mt-2 mb-2 sm:mt-8 sm:mb-5">
              <Text 
                className="text-h6 xs:text-h5 md:text-h2 text-left sm:text-center mr-2 sm:mr-3" 
                variant="h3" 
                textColor="white" 
                element="h2"
              >
                ✨ Explore AI Characters ✨
              </Text>
              
              <button
                key={'filter-button-main'}
                onClick={handleFilterButtonClick}
                className={cn(
                  'py-1 px-3 sm:py-2 sm:px-4 h-8 sm:h-10 rounded-xl border-[1px] transition-all duration-200 text-xs sm:text-sm sm:text-center font-medium flex flex-row gap-1',
                  'bg-white/10 border-white/20 hover:border-white/50 text-white/80 hover:text-white hover:scale-105',
                  'items-center justify-center'
                )}
              >
                <Filter className="w-3 h-3 sm:w-4 sm:h-4 text-white inline-block sm:mr-1" /> 
                <span className="hidden sm:inline">Filters</span>
              </button>
            </div>

            <div className="min-h-[calc(100vh)]">
              <div className="sr-only">
                <p>
                  Browse Our Collection of Free AI Girlfriends.
                  Explore our diverse collection of AI characters and create your perfect virtual girlfriend companion for free. 
                  Chat with AI girlfriends who send pictures, engage in roleplay, and offer uncensored conversations.
                  Each character has a unique personality, backstory, and conversational style. 
                  Our AI girlfriend chat simulator online lets you chat with AI characters for free.
                </p>
              </div>
              
              {loading && (!systemCharacters || systemCharacters.length === 0) ? (
                <CharectersListSkeleton />
              ) : (
                systemCharacters && <CharactersList characters={systemCharacters} className="character-zoom-effect" />
              )}
            </div>
            <div className="pt-16 md:pt-24">
              <StaggeredContentLayout sections={staggeredSections} />
            </div>
            <div className="pt-16 md:pt-24">
              <IconCardGrid content={featuresContent} />
            </div>
            <div className="pt-16 md:pt-24">
              <AccordionList content={faqContent} />
            </div>
            {!isLoggedIn && !loading && <AnonymousBanner />}
          </div>

          <Footer />
        </div>
      </SidebarLayout>
      <FilterModal isVisible={filterModalOpen} onClose={() => setFilterModalOpen(false)} />
    </>
  );
}
